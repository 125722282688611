import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';



function CarInput({onChange})  {


  const [make, setMake] = React.useState('');
  const [model, setModel] = React.useState('');
  const [vin, setVin] = React.useState('');
  const [plateNumber, setPlateNumber] = React.useState('');
  const [ownerName, setOwnerName] = React.useState('');
  const [ownerPhone, setOwnerPhone] = React.useState('');

  // const getCar = () => {
  //   return {
  //     make: make,
  //     model: model,
  //     vin: vin, 
  //     plateNumber: plateNumber,
  //     ownerName: ownerName,
  //     ownerPhone: ownerPhone
  //   }
  // }

  React.useEffect(() => {
    onChange({
      make: make,
      model: model,
      vin: vin, 
      plateNumber: plateNumber,
      ownerName: ownerName,
      ownerPhone: ownerPhone
    });
  }, [make, model, vin, plateNumber, ownerName, ownerPhone, onChange]);
   

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Въвеждане на автомобил
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            value={make}
            id="make"
            label="Марка"
            fullWidth
            autoComplete="given-name"
            variant="standard"
            onChange={(e) => setMake(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            value={model}
            id="carModel"
            label="Модел"
            fullWidth
            autoComplete="family-name"
            variant="standard"
            onChange={(e) => setModel(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            value={vin}
            id="vin"
            label="VIN"
            fullWidth
            autoComplete="shipping address-line1"
            variant="standard"
            onChange={(e) => setVin(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            value={plateNumber}
            id="plateNumber"
            name="address2"
            label="Регистрационен Номер"
            fullWidth
            autoComplete="shipping address-line2"
            variant="standard"
            onChange={(e) => setPlateNumber(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={ownerName}
            id="ownerName"
            label="Име на клиент"
            fullWidth
            autoComplete="shipping address-level2"
            variant="standard"
            onChange={(e) => setOwnerName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={ownerPhone}
            id="ownerPhone"
            label="Телефон на клиент"
            fullWidth
            variant="standard"
            onChange={(e) => setOwnerPhone(e.target.value)}
          />
        
        </Grid>
        
      </Grid>
    </React.Fragment>
  );
}

export default CarInput;
import * as React from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import AddBoxIcon from '@mui/icons-material/AddBox';
import * as Constants from './Constants.js';





export default function DataTable() {

    const [serviceId, setServiceID] = React.useState(1);
    const [carHistory, setCarHistory] = React.useState([]);
    const [rows, setRows] = React.useState([]); 

    const columns = [
      { field: 'name', headerName: 'Сервиз', width: 130 },
      { field: 'make', headerName: 'Марка', width: 70 },
      { field: 'model', headerName: 'Модел', width: 130 },
      { field: 'vin', headerName: 'VIN', width: 200 },
      {
        field: 'plateNumber',
        headerName: 'Рег. Номер',
        width: 90,
      },
      {
        field: 'ownerName',
        headerName: 'Име на клиент',
        width: 160,
      },
      { field: 'ownerPhone', headerName: 'Телефон на клиент', width: 100 },
      { field: 'oil', headerName: 'Моторно масло', width: 100 , type:'boolean'},
      { field: 'oilFilter', headerName: 'Маслен Ф.', width: 100 , type:'boolean'},
      { field: 'fuelFilter', headerName: 'Горивен Ф.', width: 100 , type:'boolean'},
      { field: 'airFilter', headerName: 'Въздушен Ф.', width: 100 , type:'boolean'},
      { field: 'cabinFilter', headerName: 'Поленов Ф.', width: 100 , type:'boolean'},
      { field: 'adBlue', headerName: 'AdBlue', width: 100 , type:'boolean'},
      { field: 'frontBrakeDisks', headerName: 'П.Дискове', width: 100 , type:'boolean'},
      { field: 'frontBrakePads', headerName: 'П.Накладки', width: 100 , type:'boolean'},
      { field: 'rearBrakeDisks', headerName: 'З.Дискове', width: 100 , type:'boolean'},
      { field: 'rearBrakePads', headerName: 'З.Накладки', width: 100 , type:'boolean'},
      { field: 'gearboxOil', headerName: 'Масло Ск. Кутия', width: 100 , type:'boolean'},
      { field: 'notes', headerName: 'Бележки', width: 200},
      { field: 'mechanicName', headerName: 'Въвел', width: 200},
      { field: 'timeCreated', headerName: 'Дата', width: 200 },
      { field: 'uploaded', headerName: 'Качена', width: 100 , type:'boolean'},
    
      { field: 'actions', type:'actions', width: 80, getActions: (params) => [
        <GridActionsCellItem
                icon={<AddBoxIcon />}
                label="Upload"
                onClick={markHistoryAsUploaded(params.row.historyId)}
              />
            ]
      }
    
    ];

    const markHistoryAsUploaded = React.useCallback((historyId) => () =>{
      //console.log(historyId);
        fetch(Constants.API_URL + '/admin/history/markHistoryAsUploaded', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"historyId": historyId}),
            credentials: 'include'
            })
            .then(response => 
            response.json());

     },[])

    const getHistory = (serviceId) => {
        //console.log(serviceId);
       fetch(Constants.API_URL + '/admin/history/getCarHistories', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"serviceId": serviceId}),
            credentials: 'include'
            })
            .then(response => 
            response.json())
            .then(data => setCarHistory(data)
            ); 
          };

    React.useEffect(() => {
        getHistory(serviceId);
    }, []);

  
    React.useEffect(() => {
      setRows(carHistory);
    }, [carHistory]);
  

  return (
    <div style={{ height: 500, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[20]}
        
      />
    </div>
  );
}
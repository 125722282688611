import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import CarHistoryInput from './carHistoryInput.js'
import CarHistoryForm from './CarHistoryForm';
import ServiceHistory from './ServiceHistory.js';
import ServiceHistoryAdmin from './ServiceHistoryAdmin.js';
import Login from './Login.js';
import Register from './Register.js';
import AdminLogin from './AdminLogin.js';
import { CookiesProvider, withCookies } from 'react-cookie';


function App() {


  return (
    <CookiesProvider>
    <Router>
      <div className="App">
        <header className="App-header">
        </header>
   
        <div className='App-body'>

          <Routes>
            <Route path="/loginPage" element={<Login/>}/>
            <Route path="/adminPage" element={<AdminLogin/>}/>
            <Route path="/registerPage" element={<Register/>}/>
            <Route path="/" element={<CarHistoryInput/>}/>
            <Route path="/history" element={<CarHistoryForm/>}/>
            <Route path="/serviceHistory" element={<ServiceHistory/>}/>
            <Route path="/serviceHistoryAdmin" element={<ServiceHistoryAdmin/>}/>
          </Routes>

        </div>
      </div>
    </Router>
    </CookiesProvider>
  );
}

export default withCookies(App);

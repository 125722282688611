import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import * as Constants from './Constants.js';

const Login = () => {


  const [token, setToken] = React.useState('');


  const navigate = useNavigate();
 


  const loginHandler = (loginData) => {
     fetch(Constants.API_URL + '/admin/login', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(loginData),
        credentials: 'include'
        })
        .then(response => 
          response.json())
        .then(data => {
          setToken(data.token);
        }
        )
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const loginData = new FormData(event.currentTarget)
    const login = {
        userName: loginData.get('userName'),
        password: loginData.get('password')
    }

    loginHandler(login);

  };

  React.useEffect(() => {
    if(token !== null && token !== ""){
      //console.log(token)
      navigate('/admin/serviceHistoryAdmin');
    } 
  }, [navigate, token])



  return (
    <React.Fragment>
    <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Вход
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="userName"
              label="Потребителско име"
              name="userName"
              autoComplete="userName"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Парола"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Вход
            </Button>
          </Box>
        </Box>
      </Container>
      </React.Fragment>
  );
}
export default Login;
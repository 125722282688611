import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import * as Constants from './Constants.js';

const columns = [
  { field: 'make', headerName: 'Марка', width: 70 },
  { field: 'model', headerName: 'Модел', width: 130 },
  { field: 'vin', headerName: 'VIN', width: 200 },
  {
    field: 'plateNumber',
    headerName: 'Рег. Номер',
    width: 90,
  },
  {
    field: 'ownerName',
    headerName: 'Име на клиент',
    width: 160,
  },
  { field: 'ownerPhone', headerName: 'Телефон на клиент', width: 100 },
  { field: 'oil', headerName: 'Моторно масло', width: 100 , type:'boolean'},
  { field: 'oilFilter', headerName: 'Маслен Ф.', width: 100 , type:'boolean'},
  { field: 'fuelFilter', headerName: 'Горивен Ф.', width: 100 , type:'boolean'},
  { field: 'airFilter', headerName: 'Въздушен Ф.', width: 100 , type:'boolean'},
  { field: 'cabinFilter', headerName: 'Поленов Ф.', width: 100 , type:'boolean'},
  { field: 'adBlue', headerName: 'AdBlue', width: 100 , type:'boolean'},
  { field: 'frontBrakeDisks', headerName: 'П.Дискове', width: 100 , type:'boolean'},
  { field: 'frontBrakePads', headerName: 'П.Накладки', width: 100 , type:'boolean'},
  { field: 'rearBrakeDisks', headerName: 'З.Дискове', width: 100 , type:'boolean'},
  { field: 'rearBrakePads', headerName: 'З.Накладки', width: 100 , type:'boolean'},
  { field: 'gearboxOil', headerName: 'Масло Ск. Кутия', width: 100 , type:'boolean'},
  { field: 'notes', headerName: 'Бележки', width: 200},
  { field: 'mechanicName', headerName: 'Въвел', width: 200},
  { field: 'timeCreated', headerName: 'Дата', width: 200 }

];



export default function DataTable() {

    const [serviceId, setServiceID] = React.useState(1);
    const [carHistory, setCarHistory] = React.useState([]);
    const [rows, setRows] = React.useState([]);

    const navigate = useNavigate();

    const getHistory = (serviceId) => {
        //console.log(serviceId);
        fetch(Constants.API_URL + '/history/getCarHistories', {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"serviceId": serviceId})
            })
            .then(response => {
              if(response.status === 403) {
                navigate('/loginPage')
              } else {
                return response.json()
              }
            }
           )
            .then(data => setCarHistory(data)
            )
          };

    React.useEffect(() => {
        getHistory(serviceId);
    }, []);

    React.useEffect(() => {
      setRows(carHistory);
      //console.log(carHistory)
  }, [carHistory]);




  return (
    <div style={{ height: 500, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[20]}
        
      />
    </div>
  );
}
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function CarHistoryForm({onChange}) {

  const [oil, setOil] = React.useState(false);
  const [oilFilter, setOilFilter] = React.useState(false);
  const [fuelFilter, setFuleFilter] = React.useState(false);
  const [cabinFilter, setCabinFilter] = React.useState(false);
  const [airFilter, setAirFilter] = React.useState(false);
  const [adBlue, setAdBlue] = React.useState(false);
  const [frontBrakeDisks, setFrontBrakeDisks] = React.useState(false);
  const [frontBrakePads, setFrontBreakePads] = React.useState(false);
  const [rearBrakeDisks, setRearBrakeDisks] = React.useState(false);
  const [rearBrakePads, setRearBrakePads] = React.useState(false);
  const [gearboxOil, setGearboxOil] = React.useState(false);
  const [notes, setNotes] = React.useState('');
  const [mechanicName, setMechanicName] = React.useState('');


  React.useEffect(() => {
    onChange({
      oil: oil,
      oilFilter: oilFilter,
      fuelFilter: fuelFilter,
      cabinFilter: cabinFilter,
      airFilter: airFilter,
      adBlue: adBlue,
      frontBrakeDisks: frontBrakeDisks,
      frontBrakePads: frontBrakePads,
      rearBrakeDisks: rearBrakeDisks,
      rearBrakePads: rearBrakePads,
      gearboxOil: gearboxOil,
      notes: notes,
      mechanicName: mechanicName
    });
  }, [adBlue, 
      airFilter, 
      cabinFilter, 
      frontBrakeDisks, 
      frontBrakePads, 
      fuelFilter, 
      gearboxOil, 
      mechanicName, 
      notes, 
      oil, 
      oilFilter,  
      rearBrakeDisks, 
      rearBrakePads,
      onChange]);

  


  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Въвеждане на история
      </Typography>
      <Grid container display="flex" direction="column" spacing={1} justify="center" alignItems="flex-start" >
      <Grid item xs={12} >
          <FormControlLabel 
            control={<Checkbox color="primary" name="saveOil" value={oil} onChange={(e) => setOil(e.target.checked)} />}
            label="Моторно масло"
          />
        </Grid>
        <Grid item xs={12}  >
          <FormControlLabel 
            control={<Checkbox color="primary" name="saveAddress" value={oilFilter} onChange={(e) => setOilFilter(e.target.checked)} />}
            label="Маслен Филтър"
          />
        </Grid>
        <Grid item xs={12} >
          <FormControlLabel
            control={<Checkbox color="primary" name="saveAddress" value={fuelFilter} onChange={(e) => setFuleFilter(e.target.checked)} />}
            label="Горивен Филтър"
          />
        </Grid>
        <Grid item xs={12} >
          <FormControlLabel
            control={<Checkbox color="primary" name="saveAddress" value={airFilter} onChange={(e) => setAirFilter(e.target.checked)} />}
            label="Въздушен филтър"
          />
        </Grid>
        <Grid item xs={12} >
          <FormControlLabel
            control={<Checkbox color="primary" name="saveAddress" value={cabinFilter} onChange={(e) => setCabinFilter(e.target.checked)} />}
            label="Поленов филтър"
          />
        </Grid>
        <Grid item xs={12} >
          <FormControlLabel
            control={<Checkbox color="primary" name="saveAddress" value={adBlue} onChange={(e) => setAdBlue(e.target.checked)}/>}
            label="AdBlue"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="primary" name="saveAddress" value={frontBrakeDisks} onChange={(e) => setFrontBrakeDisks(e.target.checked)} />}
            label="Предни спирачни дискове"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="primary" name="saveAddress" value={frontBrakePads} onChange={(e) => setFrontBreakePads(e.target.checked)} />}
            label="Предни спирачни накладки"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="primary" name="saveAddress" value={rearBrakeDisks} onChange={(e) => setRearBrakeDisks(e.target.checked)} />}
            label="Задни спирачни дискове"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="primary" name="saveAddress" value={rearBrakePads} onChange={(e) => setRearBrakePads(e.target.checked)} />}
            label="Задни спирачни накладки"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="primary" name="saveAddress" value={gearboxOil} onChange={(e) => setGearboxOil(e.target.checked)} />}
            label="Масло на скоростна кутия"
          />
        </Grid>
        </Grid>

          <TextField
            value={notes} onChange={(e) => setNotes(e.target.value)}
            id="notes"
            label="Други бележки"
            fullWidth
            variant="standard"
          />
        
          <TextField
            value={mechanicName} onChange={(e) => setMechanicName(e.target.value)}
            id="notmechanicName"
            label="Име на въвеждащия"
            fullWidth
            variant="standard"
          />

    </React.Fragment>
  );
}
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CarInput from './CarInput';
import CarHistoryForm from './CarHistoryForm';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import * as Constants from './Constants.js';

const CarHistoryInput = () => {


  const steps = ['Въвеждане на автомобил', 'Въвеждане на история'];

  const theme = createTheme();

  const [activeStep, setActiveStep] = React.useState(0);

  const [carInputData, setCarInputData] = React.useState({});
  const [carHistoryData, setCarHisotryData] = React.useState({});

  const [carId, setCarId] = React.useState(0);
  //const [serviceId, setServiceId] = React.useState(1);
  //const [mechanic_id, setMechanicId] = React.useState(1);

  const navigate = useNavigate();

  React.useEffect(() => {
    isLoggedIn();
    }, [])


    const isLoggedIn = () => {
        fetch(Constants.API_URL + '/verifyLogin', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include'
        })
        .then(response => {
            if(response.status !== 200) {
            navigate('/loginPage')
            } else {
            return true;
            }})
        };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <CarInput onChange={setCarInputData} />;
      case 1:
        return <CarHistoryForm onChange={setCarHisotryData} />;
      // case 2:
      //   return <Review />;
      default:
        throw new Error('Unknown step');
    }
  }
  const handleNext = () => {


    if (carInputData !== null && carInputData !== {} && activeStep === 0) {
      const carBody = carInputData;
      //console.log(carBody)
      saveCar(carBody)
      //console.log(carId)
    }

    if (carHistoryData !== null && carHistoryData !== {} && activeStep === 1) {
      //console.log(carHistoryData)
      const carHistoryBody = { ...carHistoryData, 'car_id': carId }
      saveCarHistory(carHistoryBody)
      //console.log(carHistoryBody)
    }
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleNewHistory = () => {
    setActiveStep(0);
  }


  const saveCar = (carBody) => {
    fetch(Constants.API_URL + '/car/addCar', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(carBody),
      credentials: 'include'
    })
      .then(response => {
        if (response.status === 403) {
          navigate('/loginPage')
        } else {
          return response.json()
        }
      })
      .then(data => setCarId(data.carId)
      )
    //console.log(carId)
  }

  const handleLogout = () => {
     fetch(Constants.API_URL + '/logout', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include'
      })
      .then(response => {
       if(response.status !== 200) {
         return
       } else {
         navigate('/loginPage')
       }
     })
  }

  const saveCarHistory = (carHistoryBody) => {
    fetch(Constants.API_URL + '/service/addHistory', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(carHistoryBody),
      credentials: 'include'
    })
      .then(response => {
        if (response.status === 403) {
          navigate('/loginPage')
        } else {
          return response.json()
        }
      })
      .then(data => setCarId(data.carId)
      )
    //console.log(carId)
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
      </AppBar>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" align="center">
            Въвеждане на автомобилна история
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                Историята е въведена успешно!
              </Typography>
              <Typography variant="subtitle1">
                <Button onClick={handleNewHistory}>Въвеждане на нова история</Button>
                <Button href='/serviceHistory'>Преглед на въведените истории</Button>
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {getStepContent(activeStep)}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Назад
                  </Button>
                )}

                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 3, ml: 1 }}
                >
                  {activeStep === steps.length - 1 ? 'Запази' : 'Напред'}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Paper>
      </Container>
      {activeStep === 0 ? (
      <Button hidden='true' variant="contained" sx={{ mt: 3, ml: 1 }} href='/serviceHistory'>Преглед на въведените истории</Button>
      ) : ("")
}   
<p></p>
                <Button
                  variant="contained"
                  onClick={handleLogout}
                  sx={{ mt: 3, ml: 1 }}
                >
                  Изход
                </Button>
    </ThemeProvider>
  )
}

export default CarHistoryInput;





